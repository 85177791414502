.splash {
    height          : 100vh;
    width           : 100vw;
    background-color: #f5f5f5;
    display         : flex;
    justify-content : center;
    align-items     : center;
    flex-direction  : column;
}

.splash .avatar-box {
    border-radius: 50%;
    width        : 400px;
    height       : 400px;
    overflow     : hidden;
}

.splash .avatar {
    width           : 400px;
    height          : 400px;
    border-radius   : 50%;
    background-color: #fff;
    display         : flex;
    justify-content : center;
    align-items     : center;
    margin-bottom   : 20px;
}

.splash .avatar img {
    width : 100%;
    height: 100%;
}