.profile-dialog-container {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
}

.profile-dialog-container .profile-dialog-list {
    padding: 40px 0;
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item {
    display        : flex;
    flex-direction : column;
    align-items    : flex-start;
    gap            : 10px;
    justify-content: center;
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item .profile-dialog-header {
    padding: 0 20px;
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item .profile-dialog-card {
    border-radius: var(--border-radius);
    min-width    : 500px;
    box-shadow   : none;
    border       : 1px solid var(--divider-color);
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item .profile-dialog-card .card-content {
    width  : 100%;
    padding: 0;
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item .profile-dialog-card .card-content .card-item {
    width        : 100%;
    padding      : 20px;
    border-bottom: 1px solid var(--divider-color);
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item .profile-dialog-card .card-content .card-item:last-child {
    border-bottom: none;
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item .profile-dialog-card .card-content .card-item .item-summary {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    gap            : 10px;
    justify-content: space-between;
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item .profile-dialog-card .card-content .card-item .accordion {
    width: 100%;
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item .profile-dialog-card .card-content .card-item .accordion .accordion-summary {
    padding: 0;
    margin : 0;
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item .profile-dialog-card .card-content .card-item .accordion .accordion-details {
    padding: 20px;
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item .accordion .accordion-details .form-container {
    width         : 100%;
    display       : flex;
    flex-direction: column;
    gap           : 20px;
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item .accordion .accordion-details .form-container .form-item {
    display       : flex;
    flex-direction: column;
    gap           : 5px;
    overflow      : hidden;
    text-overflow : ellipsis;
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item .accordion .accordion-details .form-container .form-item .input-field {
    border-radius: var(--border-radius);
    font-size    : 10px;
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item .accordion .accordion-details .form-container .form-item .input-label {
    padding-left: 10px;
    font-size   : 14px;
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item .accordion .accordion-details .form-container .form-item .error-message {
    padding-left: 10px;
    max-width: 400px;
}

.profile-dialog-container .profile-dialog-list .profile-dialog-list-item .accordion .accordion-details .form-container .form-button {
    margin-top    : 20px;
    color         : var(--paper-color);
    border-radius : var(--border-radius);
    box-shadow    : none;
    text-transform: none;
    padding       : 8px 20px;
}