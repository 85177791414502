.content-dialog .content-dialog-card {
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    align-items     : center;
    background-color: var(--light-color);
    border-radius   : 1rem;
    overflow        : auto;
}

.content-dialog .content-dialog-card .content-dialog-appbar .dialog-toolbar div.dialog-title,
.content-dialog .content-dialog-card .content-dialog-appbar .dialog-toolbar .dialog-button {
    color: var(--light-color);
}

.content-dialog .content-dialog-card .content-dialog-list {
    display       : flex;
    flex-direction: column;
    width         : 100%;
    height        : 100%;
    gap           : 3rem;
    padding       : 2rem 0;
}

.content-dialog .content-dialog-card .content-dialog-list .dialog-list-item {
    width  : 100%;
    padding: 0rem 4rem;
}

.content-dialog .content-dialog-card .content-dialog-list .dialog-list-item .dialog-textfield div {
    border-radius: 1rem;
}

.content-dialog .content-dialog-card .content-dialog-body {
    padding : 1rem;
    width   : 100%;
    overflow: hidden;
}

.content-dialog .content-dialog-card .content-dialog-table {
    display       : flex;
    flex-direction: column;
    width         : 100%;
    height        : 100%;
    gap           : 3rem;
    padding       : 2rem 0;
    overflow      : auto;
}

.content-dialog .content-dialog-card .content-dialog-table::-webkit-scrollbar {
    width : 0.5rem;
    height: 0.5rem;
}

.content-dialog .content-dialog-card .content-dialog-table::-webkit-scrollbar-thumb {
    background-color: var(--dark-grey-color);
    border-radius   : var(--border-radius);
}

.content-dialog .content-dialog-card .content-dialog-table .table-body {
    width  : 100%;
    padding: 0rem 1rem;
}

.content-dialog .content-dialog-card .content-dialog-table .table-body .table-row {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    border-bottom  : 1px solid var(--divider-color);
}

.content-dialog .content-dialog-card .content-dialog-table .table-body .table-cell.header {
    font-weight: 600;
    color      : var(--dark-grey-color);
}

.content-dialog .content-dialog-card .content-dialog-table .table-body .table-cell.content {
    white-space: pre-wrap;
    word-wrap  : break-word;
    overflow   : hidden;
}

.content-dialog .content-dialog-card .content-dialog-table .table-body .table-cell.content .dialog-textfield div {
    border-radius: var(--border-radius);
    min-width    : 20rem;
}

.content-dialog .content-dialog-card .content-dialog-table .table-body .table-cell.content .dialog-textfield input {
    text-align: center;
    padding   : 0.5rem;

}