.overview ul,
.overview ul li {
    width  : 100%;
    padding: 0;
}

ul li.subheader {
    background-color: var(--primary-background-color);
    color           : var(--primary-text-color);
    font-size       : 0.9rem;
    font-weight     : 600;
}

ul li.list-item {
    flex-direction: column;
    margin-bottom : 40px;
}

.overview .overview-card {
    width           : 100%;
    height          : 100%;
    padding         : 15px;
    background-color: var(--paper-color);
    border          : 1px solid var(--divider-color);
    border-radius   : var(--border-radius);
    box-shadow      : none;
}

.overview .overview-card .card-content {
    display       : flex;
    flex-direction: column;
    gap           : 25px;
}

.overview .overview-card .card-content h5 {
    font-size  : 1rem;
    font-weight: 600;
}

.overview .overview-card .card-content .api-key-input {
    border-radius: var(--border-radius);
}

.overview .success-button {
    color         : var(--paper-color);
    text-transform: none;
    box-shadow    : none;
    border-radius : var(--border-radius);
}

.overview .icon-button {
    border-radius: var(--border-radius);
    margin-right : 0.5rem;
}

.overview .icon-button .text {
    font-size  : 16px;
    font-weight: 500;
    margin-left: 0.5rem;
}

.overview p {
    font-size: 1rem;
}

@media only screen and (max-width: 1000px) {
    .login-container {
        width         : 100%;
        height        : 200vh;
        flex-direction: column-reverse;
        border-radius : 0;
    }
}