.privacy-policy {
    color : #525252;
    width : 100vw;
    height: 100vh;
}

.privacy-policy h1,
.privacy-policy h2 {
    margin: 20px 0;
}

.privacy-policy p {
    margin: 12px 0;

}

.privacy-policy ol {
    list-style-type: decimal;
    padding-left   : 30px;
}

.privacy-policy ul {
    list-style-type: disc;
    margin-left    : 46px;
    padding        : 0;
}

@media (min-width: 768px) and (max-width: 1023px) {
    .privacy-policy {
        font-size: 18px;
    }
}

@media (min-width: 1024px) {
    .privacy-policy {
        font-size: 20px;
        margin   : 0 auto;
        max-width: 800px;
        padding  : 20px;
    }
}