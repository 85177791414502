.api-documentation-container ul li .accordion.item {
    width      : 100%;
    padding    : 0;
    box-shadow : none;
    border-left: 8px solid var(--primary-color);
}

.api-documentation-container ul li .accordion.expanded .accordion-summary {
    background-color: var(--primary-color);
    color           : var(--paper-color);
}

.api-documentation-container ul li .accordion.expanded .accordion-summary-title .method-title {
    background-color: var(--primary-hover-color);
    color           : var(--paper-color);
}

.api-documentation-container ul li .accordion .accordion-summary {
    margin: 0;
    height: 100%;
    color : var(--paper-color);
}

.api-documentation-container ul li .accordion.expanded .accordion-summary .expand-icon {
    color: var(--paper-color);
}

.api-documentation-container ul li .accordion .accordion-summary .accordion-summary-title {
    display       : flex;
    flex-direction: row;
    gap           : 10px;
    color         : var(--secondary-text-color);
}

.api-documentation-container ul li .accordion .accordion-summary .accordion-summary-title .method-title {
    color    : var(--primary-text-color);
    font-size: 1rem;
    min-width: 100px;
}

.api-documentation-container ul li .accordion.expanded .accordion-summary .accordion-summary-title .method-title {
    color: var(--paper-color);
}

.api-documentation-container ul li .accordion .accordion-details {
    padding       : 40px;
    display       : flex;
    flex-direction: column;
    gap           : 20px;
    color         : var(--secondary-text-color);
}

.api-documentation-container ul li .accordion .accordion-details ul {
    display        : flex;
    flex-direction : row;
    align-items    : flex-start;
    justify-content: flex-start;
    gap            : 5px;
}

.api-documentation-container ul li .accordion .accordion-details ul li {
    margin: 0;
    width : 100%;
}

@media only screen and (max-width: 1000px) {
    .api-documentation-container ul li .accordion .accordion-details ul {
        flex-direction: column;
        gap           : 0;
    }
}