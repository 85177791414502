.bug-report .report-card {
    width           : 100%;
    height          : 100%;
    padding         : 8px;
    background-color: var(--paper-color);
    border          : 1px solid var(--divider-color);
    border-radius   : 80px;
    box-shadow      : none;
}

.bug-report .report-card.expanded {
    border-radius: var(--border-radius);
}

.bug-report .report-card .card-content {
    display       : flex;
    flex-direction: column;
    gap           : 25px;
}

.bug-report .report-card .card-content .avatar {
    width  : 60px;
    height : 60px;
    padding: 10px;
}

.bug-report .report-card .card-content .avatar .icon {
    width : 100%;
    height: 100%;
}

.bug-report .report-card .card-content .card-item {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    gap            : 5px;
}

.bug-report .report-card .card-content .card-item .label {
    font-size  : 0.8rem;
    font-weight: 400;
}

.bug-report .report-card .card-content .card-item .value {
    font-size  : 1rem;
    font-weight: 600;
}

.bug-report .report-card .card-content .card-item .select {
    width        : 100%;
    border-radius: var(--border-radius);
}

.bug-report .report-card .card-content .card-actions {
    display        : flex;
    flex-direction : row;
    gap            : 20px;
    align-items    : center;
    justify-content: center;
}

.bug-report .report-card .card-content .card-body {
    border-top: 1px solid var(--divider-color);
}

.bug-report .report-card .card-content .card-body .table .table-head .table-row .table-cell {
    font-size  : 1rem;
    font-weight: 600;
}

.bug-report .report-card .card-content .card-body .table .table-body .table-row .table-cell {
    background-color: var(--paper-color);
}

.bug-report .report-card .card-content .card-body .table .table-body .table-row .table-cell.status.ERROR,
.bug-report .report-card .card-content .card-body .table .table-body .table-row .table-cell.status.WARN {
    color: var(--danger-color);
}

.bug-report .report-card .card-content .card-body .table .table-body .table-row .table-cell.status.INFO,
.bug-report .report-card .card-content .card-body .table .table-body .table-row .table-cell.status.LOG {
    color: var(--log-color);
}