.video-dialog {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    padding        : 0;
}

.video-dialog .video-dialog-card {
    height : 80vh;
    padding: 0;
}

.video-dialog .video-dialog-card .dialog-container {
    height: 100vh;
}

.video-dialog .video-dialog-card .dialog-container .dialog-content-card {
    height: 80vh;
}

.video-dialog .video-dialog-card .dialog-container .dialog-content-card .card-content {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
    padding        : 0;
    width          : 100%;
    height         : 100%;
}

.video-dialog .video-dialog-card .dialog-container .dialog-content-card .card-content .video-container {
    height         : 100%;
    width          : 100%;
    min-width      : 30rem;
    position       : relative;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.video-dialog .video-dialog-card .dialog-container .dialog-content-card .card-content .video-container .video {
    width     : 100%;
    height    : 100%;
    object-fit: cover;
}

.video-dialog .video-dialog-card .dialog-container .dialog-content-card .card-content .video-details-container {
    height         : 100%;
    width          : 100%;
    position       : relative;
    display        : flex;
    flex-direction : column;
    justify-content: space-around;
    align-items    : flex-start;
    gap            : 1rem;
    overflow       : hidden;
}

.video-dialog .video-dialog-card .dialog-container .dialog-content-card .card-content .video-details-container .video-details-header {
    position       : absolute;
    top            : 0;
    left           : 0;
    width          : 100%;
    display        : flex;
    flex-direction : row-reverse;
    justify-content: space-between;
    align-items    : center;
    gap            : 1rem;
}

.video-dialog .video-dialog-card .dialog-container .dialog-content-card .card-content .video-details-container .video-details {
    height         : 100%;
    width          : 100%;
    min-width      : 30rem;
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : flex-start;
    gap            : 2rem;
    padding        : 4rem 0;
    overflow-y     : auto;
    word-wrap      : break-word;
}

.video-dialog .video-dialog-card .dialog-container .dialog-content-card .card-content .video-details-container .video-details .video-details-item {
    width          : 100%;
    padding        : 1rem 2rem;
    display        : flex;
    flex-direction : column;
    align-items    : flex-start;
    justify-content: space-between;
    gap            : 0.5rem;
    border-bottom  : 1px solid var(--divider-color);
}

.video-dialog .video-dialog-card .dialog-container .dialog-content-card .card-content .video-details-container .video-details .video-details-item .value-table .table-row {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
}