.data-container .data-card {
    display         : flex;
    flex-direction  : column;
    width           : 100%;
    height          : 100%;
    background-color: var(--light-color);
    padding         : 0;
    box-shadow      : none;
    border          : 1px solid var(--divider-color);
    border-radius   : var(--border-radius);
}

.data-container .data-card .card-header .header-box .header-box-right .add-button {
    color: var(--primary-color);
}

.data-container .data-card .table-container {
    width   : 100%;
    height  : 100%;
    overflow: auto;
}

.data-container .data-card .table-container::-webkit-scrollbar {
    width : 0.5rem;
    height: 0.5rem;
}

.data-container .data-card .table-container::-webkit-scrollbar-thumb {
    background   : var(--dark-grey-color);
    border-radius: 1rem;
}

.data-container .data-card .table-container .table {
    width          : 100%;
    border-collapse: collapse;
}

.data-container .data-card .table-container .table .table-row {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    flex-basis     : 0;
    border-bottom  : 1px solid var(--divider-color);
}

.data-container .data-card .table-container .table .table-row:last-child {
    border-bottom: none;
}

.data-container .data-card .table-container .table thead .table-row:first-child {
    font-weight  : 600;
    border-bottom: 3px solid var(--primary-color);
}

.data-container .data-card .table-container .table tbody .table-row .table-cell {
    white-space: Wrap;
    overflow   : hidden;
    padding    : 1rem 2rem;
}

.data-container .data-card .table-container .table thead .table-row .table-cell {
    font-weight: 600;
    color      : var(--dark-grey-color);
    text-align : center;
}

.data-container .data-card .table-container .table .table-row .table-cell {
    width          : 100%;
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.data-container .data-card .table-container .table .table-row .table-cell:first-child {
    justify-content: flex-start;
    padding-left   : 2rem;
}

.data-container .data-card .table-container .table .table-row .table-cell:last-child {
    justify-content: flex-end;
    padding-right  : 2rem;
    width          : 100%;
    white-space    : nowrap;
    overflow       : visible;
}

@media screen and (max-width: 1000px) {
    .content-dialog {
        margin : 0;
        padding: 0;
    }

    .content-dialog .content-dialog-card {
        position: absolute;
        bottom  : 0;
        width   : 100vw;
        margin  : 0;
    }

    .content-dialog .content-dialog-card .content-dialog-list .dialog-list-item {
        min-width: 100%;
        padding  : 0rem 2rem;
    }
}