.login-content {
    width   : 100vw;
    height  : 100vh;
    overflow: hidden;
}

.login-content .background,
.login-content .background .overlay,
.login-content .background img,
.login-content .background .content {
    width : 100%;
    height: 100%;
}

.login-content .background {
    position: relative;
}

.login-content .background img {
    object-fit: cover;
}

.login-content .background .overlay,
.login-content .background .content {
    position: absolute;
    top     : 0;
    left    : 0;
}

.login-content .background .overlay {
    background: #000000;
    opacity   : 0.65;
    z-index   : 1;
}

.login-content .background .content {
    display              : grid;
    grid-template-columns: 1fr 1fr;
    justify-items        : center;
    align-items          : center;
    z-index              : 2;
    padding              : 200px;
}

@media screen and (max-width: 1000px) {
    .login-content {
        overflow: auto;
    }

    .login-content .background .content {
        grid-template-columns: 1fr;
        padding              : 0;
        padding-top          : 20px;
    }
}