.bug-report .head {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    margin-bottom  : 20px;
}

.bug-report .head .selection {
    display        : flex;
    justify-content: center;
    align-items    : center;
    gap            : 10px;
}

.bug-report .head .selection .chip.selected {
    color: var(--paper-color);
}

.bug-report .body {
    display       : flex;
    flex-direction: column;
    justify-content: center;
    align-items   : center;
    gap           : 20px;
    margin-bottom : 20px;
}