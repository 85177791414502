.logs-container .card-header .header-box .header-box-right {
    display       : flex;
    flex-direction: row;
    gap           : 2rem;
    align-items   : center;
}

.logs-container .card-header .header-box .header-box-right .icon-button {
    color: var(--primary-color);
}

.logs-container .outer-log-list {
    border-radius   : var(--border-radius);
    background-color: #0F1924;
    overflow        : hidden;
    padding         : 1.5rem 0.5rem;
}

.logs-container .outer-log-list .log-list {
    position       : relative;
    display        : flex;
    flex-direction : column;
    color          : #CCC36A;
    font-family    : 'Roboto Mono', monospace;
    height         : 65vh;
    overflow-y     : auto;
    padding        : 2rem;
    gap            : 0.2rem;
    scroll-behavior: smooth;
}

.logs-container .outer-log-list .log-list .log-list-item {
    padding: 0;
    margin : 0;
}

.logs-container .outer-log-list .log-list .log-list-item .log-list-item-text {
    font-family: "Lucida Console", monospace;
    margin     : 0;
    padding    : 0;
}

.logs-container .outer-log-list .log-list::-webkit-scrollbar {
    width: 0.5rem;
}

.logs-container .outer-log-list .log-list::-webkit-scrollbar-track {
    background: #0F1924;
}

.logs-container .outer-log-list .log-list::-webkit-scrollbar-thumb {
    background   : var(--dark-grey-color);
    border-radius: 1rem;
}

.logs-container .outer-log-list .log-list .log-item {
    padding: 1rem 2rem;
}