:root {
  --paper-color               : #FFFFFF;
  --primary-color             : #BCD2C5;
  --primary-hover-color       : #bcd2c536;
  --primary-text-color        : #212121;
  --secondary-text-color      : #757575;
  --disabled-text-color       : #bdbdbd;
  --divider-color             : #F0F0F0;
  --primary-background-color  : #FAFAFB;
  --secondary-background-color: #E7EBF0;
  --tertiary-background-color : #fafafd;
  --darker-background-color   : #F2F2F2;
  --danger-color              : #f44336;
  --font-headline             : 'Roboto', sans-serif;
  --font-body                 : 'Roboto', sans-serif;
  --font-code                 : 'Roboto Mono', monospace;
  --font-family               : 'Roboto', sans-serif;
  --font-size-h1              : 6rem;
  --font-size-h2              : 3.75rem;
  --font-size-h3              : 3rem;
  --border-radius             : 2rem;
  --dark-color                : #212121;
  --light-color               : #ffffff;
  --light-grey-color          : #dddddd;
  --dark-grey-color           : #50505B;
  --log-color                 : #00A9E7;
}

* {
  margin    : 0;
  padding   : 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size  : 14px;
  color      : #333;
  height     : 100vh;
  width      : 100vw;
}