.profile-container .profile {
    padding     : 10px;
    gap         : 5px;
    border-color: var(--divider-color);
}

.profile-container .profile p {
    font-size: 20px;
    color    : var(--secondary-text-color);
}

.profile-container .profile .avatar {
    width           : 40px;
    height          : 40px;
    display         : flex;
    justify-content : center;
    align-items     : center;
    background-color: var(--paper-color);
    padding         : 0;
    margin          : 0;
}

.profile-container .profile .avatar .icon {
    width : 100%;
    height: 100%;
    color : var(--primary-color);
}

.profile-container .profile:hover {
    background-color: var(--primary-hover-color);
}

.menu-paper {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    gap            : 10px;
}

.menu-paper .menu-item-root {
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    gap            : 10px;
    padding        : 10px;
}