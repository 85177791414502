.api-documentation-container .accordion-details ul li .accordion.requirement {
    border-left: 1px solid var(--divider-color);
    width      : 100%;
}

.api-documentation-container .accordion-details ul li .accordion.requirement .accordion-summary {
    background-color: var(--primary-color);
    color           : var(--paper-color);
    flex-direction  : row-reverse;
    gap: 10px;
}

.api-documentation-container .accordion-details ul li .accordion.requirement .accordion-details {
    background-color: var(--primary-hover-color);
    color           : var(--paper-color);
    padding         : 0;
}