.dashboard-layout {
    height          : 100vh;
    width           : 100vw;
    overflow        : hidden;
    background-color: black;
    display         : flex;
    flex-direction  : row;
}

.dashboard-layout .overlay {
    display         : none;
    position        : absolute;
    height          : 100%;
    width           : 100%;
    background-color: rgba(0, 0, 0, 0.226);
    z-index         : 99;
}

.dashboard-layout .section {
    flex            : 1;
    display         : flex;
    flex-direction  : column;
    background-color: var(--primary-background-color);
    overflow-y      : auto;
    transition      : margin-left 0.5s ease-in-out;
}

.dashboard-layout .section .content {
    padding: 40px;
}

@media only screen and (max-width: 1000px) {
    .dashboard-layout .overlay {
        display: block;
    }
}