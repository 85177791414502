.popup .popup-content .popup-item {
    width          : 100%;
    padding        : 0.5rem 0;
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : flex-start;
    gap            : 0.1rem;
}

.popup .popup-content .popup-item .content-title,
.popup .popup-content .popup-item .content-subtitle {
    padding  : 0;
    font-size: 12px;
}

.popup .popup-content .popup-item .content-subtitle {
    font-weight  : 600;
    text-overflow: ellipsis;
}

.popup .popup-content .popup-item .table {
    width          : 100%;
    border-collapse: collapse;
}

.popup .popup-content .popup-item .table .table-row {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    flex-basis     : 0;
    border-bottom  : 1px solid var(--divider-color);
    padding        : 0;
}

.popup .popup-content .popup-item .table thead .table-row .table-cell {
    font-size: 12px;
}

.popup .popup-content .popup-item .table .table-row:last-child {
    border-bottom: none;
}

.popup .popup-content .popup-item .table thead .table-row:first-child {
    font-weight  : 600;
    border-bottom: 1px solid var(--primary-color);
}

.popup .popup-content .popup-item .table thead .table-row .table-cell,
.popup .popup-content .popup-item .table tbody .table-row .table-cell {
    padding: 0.5rem 0;
}

.popup .popup-content .popup-item .table thead .table-row .table-cell {
    font-weight: 600;
    color      : var(--primary-color);
}

.popup .popup-content .popup-item .table tbody .table-row .table-cell {
    white-space: Wrap;
    overflow   : hidden;
    font-size  : 12px;
}