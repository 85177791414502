.dashboard-layout .sidebar {
    height          : 100%;
    width           : 300px;
    background-color: var(--paper-color);
    overflow-y      : auto;
    transition      : width 0.2s ease-in-out;
    border          : 1px solid var(--divider-color);
    padding         : 20px 0;
    display         : flex;
    flex-direction  : column;
    justify-content : flex-start;
    gap             : 50px;
    z-index         : 100;
}

.dashboard-layout .sidebar::-webkit-scrollbar {
    width: 0;
}

.dashboard-layout .sidebar .list-item-header {
    color: var(--secondary-text-color);
}

.dashboard-layout .sidebar .list-item-header {
    color: var(--secondary-text-color);
}

.dashboard-layout .sidebar .list-item {
    color: var(--primary-text-color);
}

.dashboard-layout .sidebar .list-item:hover {
    background-color: var(--primary-hover-color);
}

.dashboard-layout .sidebar .list-item .icon {
    width : 30px;
    height: 30px;
    color : var(--secondary-text-color);
}

.dashboard-layout .sidebar .list-item .list-item-text {
    font-size: 20px;
}

.dashboard-layout .sidebar .list-item.active {
    border-right    : 5px solid var(--primary-color);
    background-color: var(--primary-hover-color);
    color           : var(--primary-color);
}

.dashboard-layout .sidebar .list-item.active .icon {
    color: var(--primary-color);
}

.dashboard-layout .sidebar.active {
    width: 0;
}

@media only screen and (max-width: 1000px) {
    .dashboard-layout .sidebar {
        position: absolute;
    }
}