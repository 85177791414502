.title {
    display       : flex;
    flex-direction: row;
    gap           : 20px;
    width         : 100%;
    height        : 50px;
}

.title .avatar {
    height         : 60px;
    width          : 60px;
    display        : flex;
    justify-content: center;
    align-items    : center;
    box-shadow     : 0 0 10px rgba(0, 0, 0, 0.226);
}

.title .avatar img {
    width        : 100%;
    height       : 100%;
    border-radius: 50%;
}