.data-container .data-card .table-container .table .table-row .table-cell.actions {
    gap            : 1rem;
    display        : flex;
    justify-content: flex-end;
    width          : 100%;
    padding-right  : 2rem;
    flex-direction : row;
}

.data-container .data-card .table-container .table .table-row .table-cell.actions.edit {
    gap           : 0.5rem;
    flex-direction: column;
}

.data-container .data-card .table-container .table .table-row .table-cell .dialog-textfield div {
    border-radius: var(--border-radius);
}

.data-container .data-card .table-container .table .table-row .table-cell .dialog-textfield input {
    text-align: center;
    padding   : 0.5rem;

}

.data-container .data-card .table-container .table .table-row .table-cell .table-button {
    color: var(--dark-grey-color);
}

.data-container .data-card .table-container .table .table-row .table-cell .table-button.delete {
    color: var(--danger-color);
}

.data-container .data-card .table-container .table .table-row .table-cell .dialog-button {
    color        : var(--light-color);
    border-radius: var(--border-radius);
    box-shadow   : none;
    padding      : 0.2rem;
    width        : 100%;
}

.data-container .data-card .table-container .table .table-row .table-cell .dialog-button.cancel {
    color: var(--light-grey-color);
}