.api-documentation-container {
    display        : flex;
    flex-direction : column;
    align-items    : flex-start;
    justify-content: center;
    width          : 100%;
}

.api-documentation-container ul,
.api-documentation-container ul li {
    width  : 100%;
    padding: 0;
}

.api-documentation-container ul li.subheader {
    background-color: var(--primary-background-color);
    color           : var(--primary-text-color);
    font-size       : 0.9rem;
    font-weight     : 600;
}

.api-documentation-container ul li.list-item {
    flex-direction: column;
    margin-bottom : 40px;
    border-radius : var(--border-radius);
    overflow      : hidden;
    border        : 1px solid var(--divider-color);
}