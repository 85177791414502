.instruction-item {
  display        : flex;
  flex-direction : column;
  align-items    : flex-start;
  justify-content: center;
  width          : 100%;
  margin         : 2em 0;
}

.instruction-item.sub-headline {
  margin: 1rem 0;
}

.instruction-item .title,
.instruction-item .description {
  height: auto;
}

.instruction-item .title {
  font-size  : 1.8rem;
  font-weight: bolder;
}

.instruction-item .title:first-child {
  margin-top: 0;
}

.instruction-item.sub-headline .title {
  font-size: 1.2rem;
}

.instruction-item .description {
  font-size: 1.2rem;
}

.instruction-item.sub-headline .title,
.instruction-item.sub-headline .description {
  padding-left: 2rem;
}