.fullscreen-dialog .fullscreen-dialog-card .fullscreen-dialog-appbar {
    background-color: var(--light-color);
    box-shadow      : none;
    border-bottom   : 1px solid var(--light-grey-color);
    padding         : 0.5rem 1rem;
}

.fullscreen-dialog .fullscreen-dialog-card .fullscreen-dialog-appbar .dialog-button .dialog-icon {
    font-size: 2rem;
    color    : var(--dark-grey-color);
}

.fullscreen-dialog .fullscreen-dialog-card .fullscreen-dialog-appbar .dialog-title {
    font-size: 2rem;
    color    : var(--dark-grey-color);
}

.fullscreen-dialog .fullscreen-dialog-card .fullscreen-dialog-appbar .avatar {
    width     : 3rem;
    height    : 3rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.226);
}

.fullscreen-dialog .fullscreen-dialog-card .fullscreen-dialog-appbar .avatar img {
    width : 100%;
    height: 100%;
}

.fullscreen-dialog .fullscreen-dialog-card .dialog-container {
    width           : 100%;
    height          : 100%;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    background-color: var(--primary-background-color);
}