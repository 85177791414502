.instructions-container .card-content {
    display       : flex;
    flex-direction: row;
    gap           : 2rem;
    height        : 100%;
    width         : 100%;
}

.instructions-container .card-content .table-of-contents {
    min-width  : 300px;
    height     : 100%;
    border-left: 2px solid var(--divider-color);
    padding    : 2rem;
}

.instructions-container .card-content .table-of-contents .fixed-container {
    position     : fixed;
    max-width    : 300px;
    overflow     : hidden;
    text-overflow: ellipsis;
}

.instructions-container .card-content .table-of-contents .toc-item {
    display        : flex;
    flex-direction : column;
    align-items    : flex-start;
    justify-content: center;
    padding        : 0.5rem 1rem;
    width          : 100%;
    font-weight    : 800;
}

.instructions-container .card-content .table-of-contents .toc-item:first-child {
    padding: 1rem 0rem;
}

.instructions-container .card-content .table-of-contents .toc-item .toc-header {
    font-weight: bold;
}

.instructions-container .card-content .table-of-contents .toc-item .toc-item-text {
    cursor: pointer;
}

.instructions-container .card-content .table-of-contents .toc-item .toc-item-text:hover {
    color: var(--primary-color);
}

@media only screen and (max-width: 1300px) {
    .instructions-container .card-content {
        flex-direction: column-reverse;
    }

    .instructions-container .card-content .table-of-contents {
        position   : relative;
        border-left: none;
        width      : 100%;
        padding    : 0;
    }

    .instructions-container .card-content .table-of-contents .fixed-container {
        position        : relative;
        padding         : 1rem;
        max-width       : 100%;
        background-color: var(--divider-color);
        border-radius   : var(--border-radius);
        box-shadow      : rgba(0, 0, 0, 0.09) 0px 3px 12px;
    }

    .instructions-container .card-content .table-of-contents .toc-item {
        padding: 0.5rem 2rem;
    }

    .instructions-container .card-content .table-of-contents .toc-item:first-child {
        padding: 0.5rem 1rem;
    }
}