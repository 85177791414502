.data-container.notification-service .data-card .card-content .notification-list {
    display         : flex;
    flex-direction  : column;
    gap             : 1rem;
    width           : 100%;
    background-color: var(--primary-background-color);
    padding         : 1rem;
    border-radius   : var(--border-radius);
    border          : 1px solid var(--divider-color);
}

.data-container.notification-service .data-card .card-content .notification-list .notification-card {
    width           : 100%;
    background-color: var(--primary-color);
    color           : var(--paper-color);
    border          : 1px solid var(--divider-color);
    border-radius   : var(--border-radius);
    box-shadow      : none;
}

.data-container.notification-service .data-card .card-content .notification-list .notification-card .card-content {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: space-between;
    width          : 100%;
    height         : 100%;
    padding        : 1rem 2rem;
    gap            : 1rem;
}

.data-container.notification-service .data-card .card-content .notification-list .notification-card .card-content .location-container {
    display        : flex;
    flex-direction : row;
    align-items    : flex-start;
    justify-content: center;
    gap            : 0.5rem;
}