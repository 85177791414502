.data-container.notification-service {
    display       : flex;
    flex-direction: column;
    gap           : 1rem;
    width         : 100%;
}

.data-container.notification-service .group-card-container {
    display       : flex;
    flex-direction: row;
    gap           : 1rem;
    width         : 100%;
}

.data-container.notification-service .group-card-container .group-card {
    width           : 100%;
    background-color: var(--paper-color);
    border          : 1px solid var(--divider-color);
    border-radius   : var(--border-radius);
    box-shadow      : none;
}

.data-container.notification-service .group-card-container .group-card .card-content {
    display        : flex;
    flex-direction : column;
    align-items    : flex-start;
    justify-content: center;
    width          : 100%;
    height         : 100%;
    padding        : 2rem;
    gap            : 25px;
}

.data-container.notification-service .group-card-container .group-card .card-content .content-text.content-text-bold {
    font-weight: bold;
}

.data-container.notification-service .group-card-container .group-card .card-content .input-group {
    display       : flex;
    flex-direction: column;
    gap           : 0.5rem;
    width         : 100%;
}

.data-container.notification-service .group-card-container .group-card .card-content .input-container {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: flex-start;
    gap            : 1rem;
}

.data-container.notification-service .group-card-container .group-card .card-content .input-container .label,
.data-container.notification-service .group-card-container .group-card .card-content .input-container .textfield,
.data-container.notification-service .group-card-container .group-card .card-content .input-group .button {
    font-size: 1rem;
}

.data-container.notification-service .group-card-container .group-card .card-content .input-container .input-group .textfield {
    min-width    : 300px;
    border-radius: var(--border-radius);
}

.data-container.notification-service .group-card-container .group-card .card-content .input-container .input-group .label {
    padding-left: 1.2rem;
    font-weight : bold;
}

.data-container.notification-service .group-card-container .group-card .card-content .input-group .button {
    color         : var(--paper-color);
    text-transform: none;
    box-shadow    : none;
    border-radius : var(--border-radius);
    width         : 100%;
}