.login-container {
    width    : 100%;
    max-width: 800px;
}

.login-container .login-card {
    padding        : 50px;
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    gap            : 100px;
}

.login-card .card-content {
    display       : flex;
    flex-direction: column;

    gap: 20px;
}

.login-card .card-content .login-input {
    font-size: 40px;
}

.login-card .card-content {
    display       : flex;
    flex-direction: column;
    align-items   : flex-start;
    gap           : 20px;
}

.login-card .card-content .login-button-container {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: space-between;
    gap            : 20px;
    width          : 100%;
}

.login-card .card-content .login-button {
    border-radius : 40px;
    font-weight   : bold;
    color         : #ffffff;
    text-transform: capitalize;
    font-size     : 16px;
    display       : flex;
    flex-direction: row;
    display       : flex;
}

.login-card .card-content .login-button .loading-indicator {
    margin-right: 10px;
}

.login-card .card-content .divider {
    margin        : 25px 0;
    display       : flex;
    flex-direction: row;
    gap           : 10px;
    align-items   : center;
    width         : 100%;
    color         : var(--dark-grey-color);
}

.login-card .card-content .divider .divider-line {
    flex-grow   : 1;
    height      : 1px;
    border-color: var(--light-grey-color);
    background  : #ffffff;
}

.login-card .card-content .info-button {
    color         : var(--dark-grey-color);
    border-color  : var(--light-grey-color);
    text-transform: capitalize;
    font-size     : 16px;
    font-weight   : bold;
    border-radius : 40px;
    display       : flex;
    flex-direction: row;
}

@media screen and (max-width: 1000px) {
    .login-content .login-container {
        height: 100%;
    }
    .login-container .login-card {
        padding: 50px 20px;
        box-shadow: none;
    }

    .login-card .card-content .login-input {
        font-size: 20px;
    }

    .login-card .card-content .login-button-container {
        flex-direction: column;
        gap           : 10px;
    }

    .login-card .card-content .login-button {
        width: 100%;
    }

    .login-card .card-content .divider {
        margin: 10px 0;
    }

    .login-card .card-content .info-button {
        width: 100%;
    }
}