.dashboard-layout .sidebar .sidebar-header {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    gap            : 15px;
    padding        : 10px;
    width          : 100%;
}

.dashboard-layout .sidebar .sidebar-header .avatar {
    width     : 60px;
    height    : 60px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.226);
}

.dashboard-layout .sidebar .sidebar-header .avatar img {
    width        : 100%;
    height       : 100%;
    border-radius: 50%;
}

.dashboard-layout .sidebar .sidebar-header .header-title {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    text-align     : center;
    gap            : 2px;
}

.dashboard-layout .sidebar .sidebar-header .header-title h1 {
    color         : var(--primary-text-color);
    font-size     : 25px;
    font-weight   : 800;
    letter-spacing: 5px;
}

.dashboard-layout .sidebar .sidebar-header .header-title h2 {
    color         : var(--secondary-text-color);
    font-size     : 15px;
    font-weight   : 400;
    letter-spacing: 2px;
}