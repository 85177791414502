.data-container.analysation-data .content-container {
    width         : 100%;
    height        : 60vh;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    border-radius : var(--border-radius);
    overflow      : hidden;
}

.data-container.analysation-data .card-header .header-box .header-box-right .icon-button {
    color: var(--primary-color);
}

.data-container.analysation-data .card-header .header-box .header-box-right .icon-button.download {
    border-radius: var(--border-radius);
    margin-right : 0.5rem;
}

.data-container.analysation-data .card-header .header-box .header-box-right .icon-button.download .text {
    font-size   : 14px;
    margin-right: 0.5rem;
}

.map-legend-container.fullscreen .map-legend {
    position        : absolute;
    top             : 10rem;
    right           : 2rem;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;
    background-color: var(--light-color);
    border-radius   : var(--border-radius);
    padding         : 1rem;
    z-index         : 999;
}

.map-legend-container.minimized {
    position     : relative;
    height       : 60vh;
    padding      : 1rem;
    overflow-y   : auto;
    overflow-x   : hidden;
    border       : 1px solid var(--divider-color);
    border-radius: var(--border-radius);
}

.map-legend-container .map-legend-list {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: space-between;
    width          : 100%;
    height         : 100%;
}

.map-legend-container .map-legend-list .list-item {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: flex-start;
    gap            : 0.5rem;
    width          : 100%;
    padding        : 0.5rem;
    margin         : 0;
    word-wrap      : break-word;
    word-break     : break-all;
}

.map-legend-container .map-legend-list .list-item .map-legend-color {
    width           : 1rem;
    height          : 1rem;
    border-radius   : var(--border-radius);
    background-color: aqua;
}