.small-card-container {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    width          : 100%;
    height         : 100%;
}

.small-card-container .small-card {
    padding         : 3rem 2rem;
    background-color: var(--paper-color);
    border          : 1px solid var(--divider-color);
    border-radius   : var(--border-radius);
    box-shadow      : none;
}